<template>
  <div class="global_theme_ui_page main_task_details_page_wrapper">
    <!-- Start Patterns -->
    <UserPattern :userScore="userScore"></UserPattern>
    <!-- End Patterns -->
    <!-- Task Decoding -->
    <div class="decode_task_section">
      <div
        class="d-flex justify-content-between align-items-center mb-3 hide_element_while_printing"
      >
        <h2 class="main_title m-0">{{ localization("Task Decoding") }}</h2>
        <div
          role="button"
          class="d-flex align-items-center"
          style="gap: 10px"
          @click="ToggleTaskModalForEdit('edit')"
        >
          <img src="@/assets/new-theme/edit-05.png" alt="edit" />
          <span class="primary_theme_color">{{ localization("Edit task description") }}</span>
        </div>
      </div>
      <div class="wrap_decode">
        <div class="wrap_title d-flex align-items-center justify-content-between">
          <div class="part_one">
            <img src="@/assets/new-theme/logo.png" alt="logo" />
            <span class="span_title">{{ localization("Task description") }}</span>
            <span class="span_description">- {{ mainTaskData.name }}</span>
          </div>
          <div class="d-flex align-items-center hide_element_while_printing" style="gap: 10px">
            <el-switch v-model="switchValue" active-color="#6941c6" inactive-color="#DCDFE6"></el-switch>
            <span>
              {{ localization("Border style") }}
            </span>
          </div>
        </div>
        <div
          class="for-task-description global_decode_content"
          :class="{ border_style: switchValue }"
          v-html="mainTaskData.description"
        ></div>
      </div>
    </div>

    <div class="mt-5 hide_element_while_printing">
      <SharePrintCard
        :title="`${localization('How to Use Suggested Strategies?')}`"
        :text="`${localization('Suggested strategies are based on your Learning Patterns.')}`"
        @print="printPlp"
        @share="toggleModalShare()"
        class="hide_element_while_printing"
      ></SharePrintCard>
    </div>

    <!-- Use Your Learning Patterns -->
    <div class="learner_patterns_wrapper hide_element_while_printing">
      <div class="main_cards mt-3">
        <div class="card_item">
          <div class="mb-3">
            <img class="img" src="@/assets/new-theme/profile/check-2.png" alt />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">{{ localization("Select the perfect strategies") }}</span>
            <p class="mb-0 description">
              {{ localization("Select strategies you feel might work for you under each of learning patterns and use it to complete your task.") }}

            </p>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img class="img" src="@/assets/new-theme/profile/plus-back.png" alt />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">{{ localization("Add your own strategies") }}</span>
            <p class="mb-0 description">
              {{ localization("Add your own personalized strategies under each one of the learning pattern easily.") }}
            </p>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img class="img" src="@/assets/new-theme/heart.png" alt />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">{{ localization("Use the selected strategies") }}</span>
            <p class="mb-0 description">
              {{ localization("To successfully complete your task, use the strategies you select and print or share it with others.") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Use Your Learning Patterns -->

    `
    <!-- Start Collapse Items -->
    <div class="global_theme_ui_collapse main_profile_personalize_and_collapse">
      <el-collapse class="mt-3 customization_for_collapse" v-model="panel" multiple>
        <div
          class="main_child"
          v-for="(attribute, i) in userAttributes"
          :key="i"
          :style="`border : 2px solid ${getProgressColor(i)}`"
        >
          <el-collapse-item :name="`${i}`" class :class="{ break_section: i == 'Precision' }">
            <template #title>
              <div class="custom_header_of_collapse">
                <img
                  src="@/assets/new-theme/attributes-images/sequence.png"
                  alt="sequence"
                  v-if="i == 'Sequence'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/precision.png"
                  alt="precision"
                  v-if="i == 'Precision'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/technical_reasoning.png"
                  alt="technical_reasoning"
                  v-if="i == 'Technical Reasoning'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/confluence.png"
                  alt="confluence"
                  v-if="i == 'Confluence'"
                />
                <span class="title_word">{{ i }} - {{ patternKey(i) }}</span>
                <span
                  class="selected_number"
                  v-if="
                    i == 'Sequence' &&
                      mainTaskData.selected_strategies_count.Sequence
                  "
                >
                  {{
                  mainTaskData.selected_strategies_count.Sequence +
                  `${
                  mainTaskData.selected_strategies_count.Sequence < 2
                  ? " strategy selected"
                  : " strategies selected"
                  } `
                  }}
                </span>
                <span
                  class="selected_number"
                  v-if="
                    i == 'Precision' &&
                      mainTaskData.selected_strategies_count.Precision
                  "
                >
                  {{
                  mainTaskData.selected_strategies_count.Precision +
                  `${
                  mainTaskData.selected_strategies_count.Precision < 2
                  ? " strategy selected"
                  : " strategies selected"
                  } `
                  }}
                </span>
                <span
                  class="selected_number"
                  v-if="
                    i == 'Technical Reasoning' &&
                      mainTaskData.selected_strategies_count[
                        'Technical Reasoning'
                      ]
                  "
                >
                  {{
                  mainTaskData.selected_strategies_count[
                  "Technical Reasoning"
                  ] +
                  `${
                  mainTaskData.selected_strategies_count[
                  "Technical Reasoning"
                  ] < 2
                  ? " strategy selected"
                  : " strategies selected"
                  } `
                  }}
                </span>
                <span
                  class="selected_number"
                  v-if="
                    i == 'Confluence' &&
                      mainTaskData.selected_strategies_count.Confluence
                  "
                >
                  {{
                  mainTaskData.selected_strategies_count.Confluence +
                  `${
                  mainTaskData.selected_strategies_count.Confluence < 2
                  ? " strategy selected"
                  : " strategies selected"
                  } `
                  }}
                </span>
              </div>
            </template>
            <div
              :style="
                        `color: #fff;border-radius: 8px;padding: 10px ;opacity:0.8;background-color : ${getProgressColor(i)}`
                      "
              :closable="false"
              show-icon
              :class="{ 'text-right': isRtl() }"
              v-if="attribute == false || attribute.length == 0"
            >
              {{
                getHint(i, attribute.length > 0, getProgressValue(i), attribute)
              }}
            </div>
            <!-- Start Checkboxes -->
            <div class="p-3">
              <el-collapse class="collapse_checkboxes">
                <div class="checkboxes_wrapper" v-for="(item, index) in attribute" :key="index">
                  <el-checkbox-group class v-model="selected" v-if="item">
                    <el-checkbox
                      v-if="item"
                      :label="item.id"
                      @change="updateSelectedAttributes"
                      :class="{
                        'd-none': item.level1 < 1
                      }"
                    >{{ item.level1 }}</el-checkbox>
                  </el-checkbox-group>
                  <audio controls id="audio" class="d-none">
                    <source id="audio-source" type="audio/mp3" />
                  </audio>
                  <span
                    class="span_img_wrapper hide_element_while_printing"
                    role="button"
                    @click="text_speech_audio(item.level1)"
                  >
                    <img src="@/assets/new-theme/attributes-images/volume.png" alt="volume" />
                  </span>
                </div>
                <div
                  class="your_own_statement hide_element_while_printing"
                  @click="open_strategy_dialog(i)"
                >
                  <img role="button" src="@/assets/new-theme/profile/plus.png" alt="plus" />
                  <span role="button">{{ localization("Add your own personalized statement") }}</span>
                </div>
              </el-collapse>
            </div>
            <!-- End Checkboxes  -->
          </el-collapse-item>
        </div>
      </el-collapse>
    </div>
    <!-- End Collapse Items -->

    <!-- Start What else can you do? -->
    <div class="your_personal_wrapper margin_bottom hide_element_while_printing">
      <h2 class="main_title">{{ localization("What else can you do?") }}</h2>
      <p class="mb-0 text">
        {{ localization("Print or share it with your teacher, instructor, classmates, colleagues, or even for a job inerview.") }}
      </p>
      <div class="print_share_buttons">
        <button class="btn_2 print" @click="printPlp()">
          <img class="img" src="@/assets/new-theme/profile/printer.png" alt />
          <span>{{ localization("Print this task") }}</span>
        </button>
        <button class="btn_2 share" @click="toggleModalShare()">
          <img class="img" src="@/assets/new-theme/profile/share-07.png" alt />
          <span>{{ localization("Share this task") }}</span>
        </button>
      </div>
    </div>
    <!-- Start What else can you do? -->
    <div class="your_personal_wrapper margin_bottom hide_element_while_printing">
      <h2 class="main_title">{{ localization("Have another task?") }}</h2>
      <p class="mb-3 text">
        {{ localization("Add your own task and discover what patterns are needed to successfully complete it.") }}
      </p>
      <AppButton
        @click="ToggleTaskModal('add')"
        :addbtn="true"
        :text="'Add a task'"
        class="mr-2 btn btn-light"
      ></AppButton>
    </div>

    <!-- delete section -->
    <div class="hide_element_while_printing delete_section">
      <div>
        <h2 class="main_title">{{ localization("You don't need this task anymore!") }}</h2>
        <p class="mb-0 text">{{ localization("Note: If you delete the task, you cannot restore it again.") }}</p>
      </div>
      <button class="delete_btn" @click="deleteTask()">
        <img role="button" src="@/assets/new-theme/profile/trash-01.png" alt="delete" />
        <span>{{ localization("Delete this task") }}</span>
      </button>
    </div>

    <!-- Task Modal -->
    <el-dialog
      title
      :visible.sync="dialogVisible"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      @close="resetForm"
      :close-on-click-modal="false"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/file.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title">{{ localization("Edit your study-related task") }}</h3>
        <p class="description">
          {{ localization("Edit your own task and discover what patterns are needed to successfully complete it.") }}
        </p>
      </div>

      <!-- Task Form -->
      <el-form :model="task" :rules="rules" ref="taskForm" class="task-form">
        <div class="flex justify-content-between" style="gap: 10px">
          <div class="flex-grow-1">
            <!-- Task Name -->
            <el-form-item prop="name">
              <AppInputLabel :text="`${localization('Name your task')}`" :star="true"></AppInputLabel>
              <el-input
                class="new_theme_custom_input"
                v-model="task.name"
                :placeholder="`${localization('Give your task a name')}`"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex-grow-1">
            <!-- Task Topic -->
            <el-form-item prop="topic_id">
              <AppInputLabel :text="`${localization('Task category')}`" :star="true"></AppInputLabel>
              <el-select
                v-model="task.topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in standardTopicsData"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <!-- Task Description -->
        <el-form-item prop="description">
          <AppInputLabel :text="`${localization('Task description')}`" :star="true"></AppInputLabel>
          <el-input
            class="new_theme_custom_input input_with_custom_text_area"
            type="textarea"
            v-model="task.description"
            :placeholder="`${localization('Add task description')}`"
            clearable
          />
        </el-form-item>
      </el-form>

      <!-- Footer Buttons -->
      <div class="lci_modal_buttons mt-4">
        <button @click="resetForm" class="lci_btn_modal_cancel">{{ localization("Cancel") }}</button>
        <button @click="submitForm" class="lci_btn save lci_btn_modal_cancel">{{ localization("Confirm") }}</button>
      </div>
    </el-dialog>
    <!-- End Add Task Modal -->

    <el-dialog
      :title="`${localization('Add a Task')}`"
      :visible.sync="strategy_Dialog"
      width="600px"
      class="add_strategy_dialog_task_user"
      :class="{ is_rtl: isRtl() }"
      @close="resetForm"
      top="1%"
      custom-class="task-modal global_modal"
      :close-on-click-modal="true"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/icons/file.png" alt="file" />
          <img
            @click="
              strategy_Dialog = false;
              strategy.level1 = null;
            "
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title font-weight-bold mt-3">{{ localization("Add Strategy") }}</h3>
        <p class="description">{{ localization("Add your own personalized statement.") }}</p>
      </div>
      <el-form
        :model="strategy"
        style="width: 100%"
        :rules="rules"
        ref="addStrategyForm"
        :class="{ form_with_rtl: isRtl() }"
      >
        <el-form-item :label="`${localization('Strategy')}`" style="width: 100%" prop="level1">
          <el-input
            type="text"
            v-model="strategy.level1"
            :placeholder="`${localization('Please enter a strategy')}`"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer user_profile_dialog_footer">
        <el-button @click="strategy_Dialog = false">{{
          localization("Cancel")
        }}</el-button>
        <el-button type="primary" @click="add_custom">{{
          localization("Save")
        }}</el-button>
      </span>-->

      <div class="lci_modal_buttons mt-5">
        <button
          @click="
            strategy_Dialog = false;
            strategy.level1 = null;
          "
          class="lci_btn_modal_cancel"
        >{{ localization("Cancel") }}</button>
        <button @click="add_custom" class="lci_btn save lci_btn_modal_cancel">{{ localization("Add") }}</button>
      </div>
    </el-dialog>

    <!--  Start Share -->
    <ShareModal
      :showHideModalShare.sync="showHideModalShare"
      @close="toggleModalShare"
      @submit="submitShared"
      :title="`${localization('Share your Task')}`"
      :description="`${localization('Share your personalized work profile and learning patterns score with others to let them know how you learn, work, and interact with others.')}`"
    ></ShareModal>
    <!--  End Share -->

    <!-- Task Modal -->
    <el-dialog
      title
      :visible.sync="taskDialogVisible"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      @close="resetTaskForm"
      :close-on-click-modal="false"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/file.png" alt="file" />
          <img
            @click="resetTaskForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title">{{ type == "add" ? localization("Add a new study-related task") : localization("Edit your study-related task") }} </h3>
        <p class="description">
          {{ localization("Add your own task and discover what patterns are needed to successfully complete it.") }}
        </p>
      </div>
      <!-- Task Form -->
      <el-form :model="task" :rules="rules" ref="taskForm" class="task-form">
        <div class="flex justify-content-between" style="gap: 10px">
          <div class="flex-grow-1">
            <!-- Task Name -->
            <el-form-item prop="name">
              <AppInputLabel :text="`${localization('Name your task')}`" :star="true"></AppInputLabel>
              <el-input
                class="new_theme_custom_input"
                v-model="task.name"
                :placeholder="`${localization('Give your task a name')}`"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex-grow-1">
            <!-- Task Topic -->
            <el-form-item prop="topic_id">
              <AppInputLabel :text="`${localization('Task category')}`" :star="true"></AppInputLabel>
              <el-select
                v-model="task.topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in standardTopicsData"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <!-- Task Description -->
        <el-form-item prop="description">
          <AppInputLabel :text="`${localization('Task description')}`" :star="true"></AppInputLabel>
          <el-input
            class="new_theme_custom_input input_with_custom_text_area"
            type="textarea"
            v-model="task.description"
            :placeholder="`${localization('Add task description')}`"
            clearable
          />
        </el-form-item>
      </el-form>
      <div class="or_modal mb-3">
        {{ localization("or") }}
      </div>
      <!-- File Upload Section -->
      <div class="file-upload">
        <el-upload
          class="upload-demo"
          drag
          action
          :on-change="handleUploadSuccess"
          :before-upload="beforeUpload"
          :file-list="fileList"
          :auto-upload="false"
          :on-remove="handleRemove"
          :show-file-list="false"
          accept="image/apng, image/jpeg, image/png, image/webp"
          :disabled="loading"
        >
          <div v-if="!loading">
            <img src="@/assets/new-theme/upload.png" alt="upload" />
            <div class="el-upload__text">
              <span class="click_upload">
                {{ localization("Click to upload") }}
                </span>
                {{ localization("or drag and drop") }}
            </div>
            <div class="el-upload__tip">Supported: PNG, JPG, (Max 5MB)</div>
          </div>
          <div
            class="d-flex spin_modal"
            v-loading="loading"
            element-loading-text="waiting to extract photo..."
          ></div>
        </el-upload>
      </div>

      <!-- Display Uploaded Files with Delete Option -->
      <div v-if="fileList && fileList.length > 0" class="uploaded-files">
        <ul>
          <li v-for="file in fileList" :key="file.name" class="file-item">
            <div class="d-flex" style="gap: 10px">
              <div>
                <img src="../../../assets/new-theme/file.png" alt />
              </div>
              <div>
                <span class="file-name">{{ file.name }}</span>
                <span class="d-block file_size">{{ (file.size / 1024).toFixed(2) }} KB</span>
              </div>
            </div>
            <i class="el-icon-delete file-delete" @click="removeFile(file)"></i>
          </li>
        </ul>
      </div>

      <!-- Footer Buttons -->
      <div class="lci_modal_buttons mt-4">
        <button @click="resetTaskForm" class="lci_btn_modal_cancel">{{ localization("Cancel") }}</button>
        <button @click="submitForm" class="lci_btn save lci_btn_modal_cancel">{{ localization("Confirm") }}</button>
      </div>
    </el-dialog>
    <!-- End Add Task Modal -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";

export default {
  name: "TaskDetails",
  data() {
    return {
      showHideModalShare: false,
      emailForm: {
        email: "",
        subject: "",
        message: ""
      },
      emailRules: {
        email: [
          {
            required: true,
            message: "Please enter an email address",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: "blur"
          }
        ]
      },
      sharingPdf: false,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selected: [],
      taskStrategies: [],
      fileList: [],
      strategy_Dialog: false,
      strategy: {},
      type: "",
      mainTask: {},
      showAddTask: false,
      taskDialogVisible: false,
      loading: false,
      switchValue: false,
      task: {
        name: "",
        topic_id: "",
        description: ""
      },
      dialogVisible: false,
      rules: {
        name: [
          {
            required: true,
            message: "Please enter the task name",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please enter the task topic",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please enter a task description",
            trigger: "blur"
          }
        ]
      },
      showHideModalUserType: false
    };
  },
  computed: {
    mainUserId() {
      return this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userAttributes() {
      return this.taskStrategies;
      // return this.$store.getters["user/onetask"];
    },
    mainTaskData() {
      return this.mainTask;
      // return this.$store.getters["user/onetask"];
    },
    isAuthEqualUser() {
      return this.auth().id == this.mainUserId;
    },
    userData() {
      return this.$store.state.user.user;
    },
    taskId() {
      return this.$route.params.task_id;
    },
    standardTopicsData() {
      return this.$store.getters["topics/getStandardTopics"];
    }
  },
  methods: {
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set the Selected User attribute
        // this.selected = this.userData.selected_attributes;
        this.$store.dispatch("topics/handlerStandardTopics");
        if (this.userData.sequence) {
        } else {
          this.$router.push("/new-theme/instructions");
        }
      });
    },
    open_strategy_dialog(pattern) {
      this.strategy.pattern = pattern;
      this.strategy_Dialog = true;
    },
    add_custom() {
      this.$refs["addStrategyForm"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("strategies/addCustom", {
              id: this.taskId,
              query: this.strategy
            })
            .then(_ => {
              this.strategy_Dialog = false;
              this.strategy.level1 = "";
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
              this.refresh();
            });
        } else {
          // if not all required fields filled with valide values
          return false;
        }
      });
    },
    printPlp() {
      window.print();
    },
    // Toggle Modal User Type
    toggleModalShare() {
      this.showHideModalShare = !this.showHideModalShare;
    },
    submitShared(sharedEmails) {
      let payload = {
        id: this.taskId,
        emails: sharedEmails
      };
      console.log('-------------',sharedEmails,this.taskId);

      this.$store.dispatch("user/share_school_task", payload).then(() => {
        this.showHideModalShare = !this.showHideModalShare;
        Swal.fire({
          title: "",
          text: `${this.localization("Done")}`,
          confirmButtonText: `${this.localization("Ok")}`,
          icon: "success",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        });
      });
    },
    getProgressValue(key) {
      switch (key) {
        case "Precision":
          return this.userScore.precision;
        case "Technical Reasoning":
          return this.userScore.technical_reasoning;
        case "Confluence":
          return this.userScore.confluence;
        case "Sequence":
          return this.userScore.sequence;
      }
    },
    getHint(key, decode, score, item) {
      if (key == "Sequence") {
        return `${this.localization("Congratulations! Since there is no Sequence required in this task and you avoid Sequence, no strategies are needed.")}`;
      } else if (key == "Precision") {
        return `${this.localization("Congratulations! Since there is no Precision required in this task and you avoid Precision, no strategies are needed.")}`;
      } else if (key == "Technical Reasoning") {
        return `${this.localization("Congratulations! Since there is no Technical Reasoning required in this task and you avoid Technical Reasoning, no strategies are needed.")}`;
      } else if (key == "Confluence") {
        return `${this.localization("Congratulations! Since there is no Confluence required in this task and you avoid Confluence, no strategies are needed.")}`;
      }
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    // Return pattern key in collapse
    patternKey(name) {
      if (name == "Sequence") {
        // Start Pattern range
        if (this.userScore?.sequence >= 25 && this.userScore.sequence <= 35) {
          return "Use First";
        } else if (
          this.userScore?.sequence >= 7 &&
          this.userScore.sequence <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.sequence >= 18 &&
          this.userScore.sequence <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Precision") {
        // Start Pattern range
        if (this.userScore?.precision >= 25 && this.userScore.precision <= 35) {
          return "Use First";
        } else if (
          this.userScore?.precision >= 7 &&
          this.userScore.precision <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.precision >= 18 &&
          this.userScore.precision <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Technical Reasoning") {
        // Start Pattern range
        if (
          this.userScore?.technical_reasoning >= 25 &&
          this.userScore.technical_reasoning <= 35
        ) {
          return "Use First";
        } else if (
          this.userScore?.technical_reasoning >= 7 &&
          this.userScore.technical_reasoning <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.technical_reasoning >= 18 &&
          this.userScore.technical_reasoning <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Confluence") {
        // Start Pattern range
        if (
          this.userScore?.confluence >= 25 &&
          this.userScore.confluence <= 35
        ) {
          return "Use First";
        } else if (
          this.userScore?.confluence >= 7 &&
          this.userScore.confluence <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.confluence >= 18 &&
          this.userScore.confluence <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
    },
    // Get task details
    refresh() {
      this.$store.dispatch("user/show_task", { id: this.taskId }).then(res => {
        this.taskStrategies = res?.task?.strategy_id;
        this.mainTask = res?.task;
        this.selected =
          res?.task?.selected_strategy_id !== null
            ? res?.task?.selected_strategy_id
            : [];
        let html_task_handler = document.querySelector(".for-task-description");
        const all_child_nodes = Array.from(
          html_task_handler.getElementsByTagName("div")
        );
        all_child_nodes.forEach(element => {
          let get_tooltip_data = element.getAttribute("data-tooltip");
          let create_span = document.createElement("span");
          create_span.textContent = get_tooltip_data;
          create_span.setAttribute("class", "span_for_tooltips");
          element.appendChild(create_span);
        });
      });
    },
    stripHtml(html) {
      if (!html) return "";
      return html.replace(/<[^>]*>/g, ""); // Remove all HTML tags
    },
    updateSelectedAttributes() {
      this.$store
        .dispatch("user/update_task", {
          id: this.taskId,
          query: { selected_strategy_id: this.selected }
        })
        .then(() => {
          this.getMainUserData();
          this.refresh();
        });
    },

    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },
    // Edit Task
    ToggleTaskModalForEdit(type) {
      this.type = type;
      this.task.name = this.mainTask.name;
      this.task.description = this.stripHtml(this.mainTask.description);
      this.task.topic_id = this.mainTask.topic_id;
      this.dialogVisible = !this.dialogVisible;
    },
    handleUploadSuccess(file, fileList) {
      this.fileList = fileList;
      this.handle_upload_file_img(file);
    },
    resetTaskForm() {
      this.taskDialogVisible = false;
    },
    resetForm() {
      this.$refs.taskForm.resetFields();
      this.dialogVisible = false;
      // this.$v.$reset();
    },
    ToggleTaskModal(type) {
      if (type == 'add'){
        this.task = {
          name: "",
          topic_id: "",
          description: ""
        }
      }
      this.showAddTask = !this.showAddTask;
      this.taskDialogVisible = !this.taskDialogVisible;
      this.type = type;
    },
    handle_upload_file_img(file) {
      this.loading = true;
      this.description_photo = file.raw;
      let payload = new FormData();
      payload.append("photo", file.raw);
      this.$store
        .dispatch("user/get_ocr_photo_description", payload)
        .then(response => {
          this.task.description = response.photo_description;
          this.loading = false;
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Please double check the extracted text!")}`,
            type: "success",
            confirmButtonText: `${this.localization("Ok")}`,
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        });
      this.fileList = [];
      this.loading = false;
    },
    submitForm() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          if (this.type == "add") {
            this.$store
              .dispatch("user/add_task", {
                query: this.task
              })
              .then(response => {
                this.dialogVisible = false;

                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.$router.push(`/new-theme/tasks/${response.task.id}`);
                this.refresh({});
                this.taskDialogVisible = !this.taskDialogVisible;
              })
              .catch(_ => {
                this.dialogVisible = false;
              });
          } else {
            this.$store
              .dispatch("user/edit_task", {
                query: this.task,
                id: this.taskId
              })
              .then(_ => {
                this.dialogVisible = false;

                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.refresh({});
              })
              .catch(_ => {
                this.dialogVisible = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    // submitForm() {
    //   this.$refs.taskForm.validate(valid => {
    //     if (valid) {
    //       this.$store
    //         .dispatch("user/edit_task", {
    //           query: this.task,
    //           id: this.taskId
    //         })
    //         .then(_ => {
    //           this.dialogVisible = false;

    //           Swal.fire({
    //             title: "",
    //             icon: "success",
    //             text: `${this.localization("Done")}`,
    //             confirmButtonText: `${this.localization("Ok")}`,
    //             type: "success",
    //             confirmButtonClass: "btn btn-success ",
    //             buttonsStyling: false
    //           });
    //           this.taskDialogVisible = false;
    //           this.refresh({});
    //           window.scrollTo({ top: 0, behavior: "smooth" });
    //         })
    //         .catch(_ => {
    //           this.dialogVisible = false;
    //           this.taskDialogVisible = false;
    //         });
    //     }
    //   });
    // },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("File size cannot exceed 5MB.");
      }
      return isLt5M;
    },
    deleteTask() {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          let payload = {
            tasks: [this.$route.params.task_id]
          };
          this.$store.dispatch("tasks/handleDeleteTasks", payload).then(_ => {
            this.$router.push("/new-theme/tasks");
            Swal.fire({
              title: "",
              icon: "success",
              type: "success",
              text: `${this.localization("Deleted")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              confirmButtonClass: "btn btn-success ",
              buttonsStyling: false
            });
          });
        }
      });
    }
  },
  mounted() {
    this.getMainUserData();
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.main_task_details_page_wrapper {
  // Use Your Learning Patterns
  .learner_patterns_wrapper {
    margin-top: 40px;
    .titles_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .print_share_buttons {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .text {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }
    .main_cards {
      display: flex;
      justify-content: space-between;
      gap: 4%;
      .card_item {
        border-radius: 8px;
        border: 1px solid #eaecf0;
        padding: 24px;
        width: calc(96% / 3);

        .title {
          color: #101828;
          font-weight: 600;
          font-size: 18px;
        }
        .description {
          color: #667085;
          font-weight: 400;
          font-size: 16px;
        }
        .lci_btn {
          background-color: #f2f4f7;
          color: #6941c6;
          border-radius: 8px;
          padding: 12px 20px;
          border: 1px solid #eaecf0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }

  .main_title {
    color: #101828;
    font-weight: 600;
    font-size: 20px;
    &.white_text {
      color: #fff;
    }
  }
  .text {
    color: #667085;
    font-weight: 400;
    font-size: 16px;
  }
  .your_personal_wrapper {
    border-radius: 8px;
    border: 1px solid #eaecf0;
    padding: 24px;
    &.margin_bottom {
      margin-bottom: 24px;
    }
    .print_share_buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 20px;
      .btn_2 {
        border: 1px solid transparent;
        border-radius: 8px;
        color: #6941c6;
        font-size: 14px;
        height: 41px;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        font-weight: 500;
        gap: 10px;
        background: #fff;
        flex-grow: 1;
        justify-content: center;
        &.share {
          background-color: #f9f5ff;
        }
        &.print {
          border-color: #6941c6;
        }
      }
    }
  }
  .delete_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .delete_btn {
      border: none;
      background: none;
      color: #b42318;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>

<style lang="scss">
.user_modal {
  padding: 0;
  .el-dialog__header {
    display: none !important;
  }
}

.main_profile_personalize_and_collapse {
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__content {
    box-shadow: none !important;
    border: none !important;

    // @media print {
    //   padding-bottom: 0;
    // }
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
    font-size: 16px;
    line-height: inherit;
    color: #344054 !important;
  }
  .el-checkbox__inner {
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    height: 20px;
    width: 20px;
  }
  .el-checkbox {
    align-items: flex-start;
    display: flex;
  }
  .el-checkbox__label,
  .el-checkbox is-checked {
    color: #344054 !important;
    font-weight: 400;
    font-size: 16px;

    // max-width: 98%;
    word-break: break-word !important;
    white-space: pre-wrap;
    // word-wrap: break-word;
    white-space: normal;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #6941c6;
    border-color: #6941c6;
  }
  .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
    border-width: 2px;
  }
}
::v-deep .input_with_custom_text_area .el-textarea__inner {
  word-break: break-word !important; /* Ensures long words break */
  overflow-wrap: break-word !important; /* Ensures text wraps properly */
  white-space: pre-wrap !important; /* Preserves line breaks */
}
</style>
